// Angular Files
import { DOCUMENT, Location } from '@angular/common';
import { Component, DoCheck, Inject, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormControl, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

// Angular Material Files
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';

// Other External Files
import { RecaptchaComponent } from 'ng-recaptcha';
import { first, Subscription } from 'rxjs';

// Payment Integration Files
import { PaymentProcessorProvider } from 'apps/public-portal/src/app/payment-integrations/base';
import {
    BasePaymentProcessorComponent,
    BillingInfoComponent,
    BillingInfoFields,
    PaymentIntegrationFieldValue
} from 'apps/public-portal/src/app/payment-integrations/base/components';
import {
    ECheckAccountOwnerTypeEnum,
    ECheckAccountTypeEnum,
    IFramePaymentResponse,
    PaymentMethodTypeEnum
} from 'apps/public-portal/src/app/payment-integrations/base/models';
import { FisService } from 'apps/public-portal/src/app/payment-integrations/fis/service';

// Teller Online Files
import { AuthService, CartModel, CartService, InboundRedirectService } from 'apps/public-portal/src/app/core/services';

// Teller Online Library Files
import { StateOrProvince } from 'apps/public-portal/src/app/shared/constants';
import { PaymentIntegrationEnumDto } from 'libraries/core/src/lib/api/CoreWebApi';
import {
    TellerOnlineAppService,
    TellerOnlineErrorHandlerService,
    TellerOnlineSiteMetadataService
} from 'teller-online-libraries/core';
import { TellerOnlineMessageService, TellerOnlineValidationService, TellerOnlineWindowService } from 'teller-online-libraries/shared';

/** Represents an HTML element that is "loaded" from the tokenizationurl for fis */
interface Tokenizer extends HTMLElement {
    jwt: any;
    token: string;
    canDelete: boolean;
    paymentType: string;
    styles: string;
    openIframe: any;
    addEventListener: any;
}

/** Represents an HTML element that is "loaded" from the multipayurl for fis */
interface MultiPay extends HTMLElement {
    requireEmail: boolean;
    jwt: any;
    allowedPaymentMethods: string[];
    showReceipt: boolean;
    showTerms: boolean;
    termsUrl: string;
    enforceTermsLinkClick: boolean;
    cardDoubleEntry: boolean;
    cvvDoubleEntry: boolean;
    bankAccountDoubleEntry: boolean;
    bankRoutingDoubleEntry: boolean;
    maskCard: boolean;
    maskCvv: boolean;
    maskBankAccount: boolean;
    sessionIdleTime: number; //min of 3 minutes, max of 15 minutes
    styles: string;
    addEventListener: any;
    openIframe: any;
    merchantAlias: string;
    merchantDisplayName: string;
    userParts: any[];
    serviceFeeLabel: string;
    internationalAddress: boolean;
    militaryAddress: boolean;
    defaultBillingAddress: { [key: string]: any };
    lineItems: any[];
}

@Component({
    selector: 'app-fis',
    templateUrl: './fis.component.html',
    styleUrls: ['./fis.component.scss'],
    host: {
        class: 'fis'
    }
})
export class FisComponent extends BasePaymentProcessorComponent implements OnInit, DoCheck, OnDestroy {
    @ViewChild(BillingInfoComponent) billingInfoComponent!: BillingInfoComponent;
    @ViewChild("fisIframe") fisIframe;
    @ViewChild("reCaptcha") reCaptcha: RecaptchaComponent;

    // Public variables
    public fisFieldError: string = null;
    public fisFieldsAvailable: boolean = false;
    public fisIframeLoaded: boolean = false;
    // Multipay variables
    public useMultipay: boolean;

    public captchaCode: string = this.validationService.captchaCode;

    private _captchaToken: string = null;
    private _expiryTime: number;
    private _cartHashRefreshTime: number;
    private _cartHash: string;

    public get creditDisclaimer() {
        return `By clicking the ${this.forEdit ? 'Save' : 'Pay'} button, I authorize County of Santa Clara to charge the credit card indicated on this web form for the noted amount. I understand that in the event this transaction is rejected by my financial institution, I may be subject to an $85 returned payment fee. I understand a late payment is liable to a 10% penalty. I understand that County of Santa Clara requires at least 2 weeks prior notice to cancel this authorization.

        <b>Note:</b> Your credit card information is not provided to County of Santa Clara. Therefore, your Credit Card, Debit Card or E-Check information does not appear on, and is not retained on, the County of Santa Clara network, computers or data storage systems.`;
    }

    public requiredAddressFields: BillingInfoFields[] = [
        BillingInfoFields.addressLine1,
        BillingInfoFields.addressState,
        BillingInfoFields.addressCity,
        BillingInfoFields.phone
    ]
    public disabledAddressFields: BillingInfoFields[] = [
        BillingInfoFields.addressLine1,
        BillingInfoFields.addressState,
        BillingInfoFields.addressCountry,
        BillingInfoFields.addressCity,
        BillingInfoFields.phone,
        BillingInfoFields.addressZip
    ]

    public TokenizerAction = TokenizerAction; // To be used in the markup

    // Private variables
    private _formChangeSubscription: Subscription;
    private _feeToken: string;
    private _preparePaymentError: string;
    private _creditCardConfirmed: boolean;
    private _fisPopup: MatDialogRef<any>;
    // multipay variables
    private _paymentIdentifier: string = null;
    private _jwt: string = null;
    private _modalExtensionInterval;
    private _cartChangeInterval;

    // Constants
    private COMPONENT_SCRIPT_ID = 'fisComponentScript';
    // These are defined by multipay documentation but are constants here incase they change at some point
    private MIN_MULTIPAY_EXPIRY = 3;
    private MAX_MULTIPAY_EXPIRY = 15;
    // We want to ensure we are not too close to the lambda runtime, so the expiry time will be less than the lambda's
    // time by this amount of minutes
    private EXPIRY_OFFSET = 2;

    // #region BasePaymentProcessorComponent property overrides
    public set paymentMethodData(paymentMethodData) {
        if (this._formChangeSubscription) this._formChangeSubscription.unsubscribe();

        super.paymentMethodData = paymentMethodData;

        // TODO: TOP-2624 remove the useMultipay flag after testing
        if (!this.forEdit && this.useMultipay) {
            this.paymentDetailsForm.addControl("Email", new FormControl(paymentMethodData.billingInfo.email));
        } else {
            // dynamically add all of the cc fields to the controls for the form group
            if (paymentMethodData.type == PaymentMethodTypeEnum.CreditCard) {
                Object.keys(this.CC_FIELDS).filter(f => f != 'ccname').forEach(name => {
                    this.paymentDetailsForm.addControl(name, new FormControl(new PaymentIntegrationFieldValue(null, '', name, this.CC_FIELDS[name])));
                });
                this.paymentDetailsForm.addControl(this.CC_FIELDS.ccname, new FormControl(paymentMethodData.billingInfo.fullName));
                // dynamically add all of the echeck fields to the controls for the form group
            } else if (paymentMethodData.type == PaymentMethodTypeEnum.ECheck) {
                Object.keys(this.ECHECK_FIELDS)
                    .filter(f => !['checkname', 'checktype', 'checkownertype', 'checkaccountvalidator', 'checkabavalidator'].includes(f))
                    .forEach(name => {
                        this.paymentDetailsForm.addControl(name, new FormControl(new PaymentIntegrationFieldValue(null, '', name, this.ECHECK_FIELDS[name])));
                    });
    
                this.paymentDetailsForm.addControl(this.ECHECK_FIELDS.checkname, new FormControl(paymentMethodData.billingInfo.fullName));
                this.paymentDetailsForm.addControl(this.ECHECK_FIELDS.checktype, new FormControl({ value: [], disabled: true }));
                this.paymentDetailsForm.addControl(this.ECHECK_FIELDS.checkowner, new FormControl({ value: [], disabled: true }));
            }
        }

        if (paymentMethodData) {
            this._formChangeSubscription = this.paymentDetailsForm.valueChanges.subscribe((value) => {
                if (!this.forEdit && this.useMultipay) {
                    paymentMethodData.billingInfo.email = value["Email"];
                } else {
                    if (paymentMethodData.type == PaymentMethodTypeEnum.CreditCard) {
                        paymentMethodData.billingInfo.fullName = value[this.CC_FIELDS.ccname];
                    } else {
                        paymentMethodData.echeckAccountType = value[this.ECHECK_FIELDS.checktype];
                        paymentMethodData.billingInfo.fullName = value[this.ECHECK_FIELDS.checkname];
                    }
                }
            });
        }
    }

    public get paymentMethodData() {
        return super.paymentMethodData;
    }

    // #endregion

    // Subscriptions

    constructor(
        private fisService: FisService,
        private windowService: TellerOnlineWindowService,
        private errorHandlerService: TellerOnlineErrorHandlerService,
        private dialog: MatDialog,
        @Inject(DOCUMENT) private document: Document,
        ngZone: NgZone,
        location: Location,
        appService: TellerOnlineAppService,
        siteMetadataService: TellerOnlineSiteMetadataService,
        inboundRedirectService: InboundRedirectService,
        cartService: CartService,
        authService: AuthService,
        messageService: TellerOnlineMessageService,
        validationService: TellerOnlineValidationService,
        paymentProvider: PaymentProcessorProvider,
        matIconRegistry: MatIconRegistry,
        domSanitizer: DomSanitizer
    ) {
        super(appService, ngZone, location, siteMetadataService, inboundRedirectService, cartService, authService, messageService, validationService, paymentProvider, matIconRegistry, domSanitizer);
        this.loading = true;
        this._creditCardConfirmed = false;
        this.useMultipay = fisService.useMultiPay;
    }

    //#region OnInit Implementation

    ngOnInit() {
        super.ngOnInit();

        this.paymentProvider.configLoaded$.subscribe(loaded => {
            if (loaded) {
                this._cartHashRefreshTime = this.paymentProvider.defaultConfig.determineCartHashRefreshTime(this.paymentMethodData.type);
                this._expiryTime = this.paymentProvider.defaultConfig.determineProcessorExpiryTime(this.paymentMethodData.type);
                // Ensure the expiry meets the requires of the multipay iframe
                // We will be subtracting 2 minutes from the expiry time
                if (this._expiryTime < this.MIN_MULTIPAY_EXPIRY + this.EXPIRY_OFFSET) {
                    this.errorHandlerService.handleError({
                        message: "FIS ReProcessingLagMinutes was lower than the minimum (3) for MultiPay Iframe and may cause payment inconsistencies: " + this._expiryTime});
                    this._expiryTime = this.MIN_MULTIPAY_EXPIRY;
                } else if (this._expiryTime > this.MAX_MULTIPAY_EXPIRY + this.EXPIRY_OFFSET) {
                    this.errorHandlerService.handleError({
                        message: `FIS ReProcessingLagMinutes was higher than the maximum (15) for MultiPay Iframe: ${this._expiryTime} -- using the max (15) instead`});
                    this._expiryTime = this.MAX_MULTIPAY_EXPIRY;
                } else {
                    // We want to ensure we are always 2 minutes behind the lambda's runtime so we don't have any collisions
                    this._expiryTime -= this.EXPIRY_OFFSET;
                }
                this._initializeComponentJS();
            }
        });
    }
    //#endregion

    //#region DoCheck Implementation

    ngDoCheck(): void {
        super.ngDoCheck();
    }

    //#endregion

    //#region OnDestroy Implementation

    ngOnDestroy(): void {
        super.ngOnDestroy();
        if (this._formChangeSubscription) this._formChangeSubscription.unsubscribe();
        this._closeFisPopup();
        this.paymentMethodData.cardNumber = undefined;
        this.paymentMethodData.cardExpiry = undefined;
        this.paymentMethodData.billingInfo.addressCity = undefined;
        this.paymentMethodData.billingInfo.addressLine1 = undefined;
        this.paymentMethodData.billingInfo.addressLine2 = undefined;
        this.paymentMethodData.billingInfo.addressRegion = undefined;
        this.paymentMethodData.billingInfo.addressState = undefined;
        this.paymentMethodData.billingInfo.addressZip = undefined;
        this.paymentMethodData.billingInfo.phone = undefined;
    }

    //#endregion

    //#region Event Handlers

    onSubmit_validateAndSubmit = (e) => {
        e.preventDefault();
        this._validateAndSubmit();
    };

    //#endregion

    //#region BasePaymentProcessorComponent Implementation

    public override async savePaymentMethod() {
        this.appService.triggerPageLoading('Saving information...');

        try {
            let response = await this.fisService.savePaymentMethod({
                paymentMethodData: this.paymentMethodData,
                paymentToken: this._paymentToken,
                paymentMethodId: this.paymentMethodId,
                captchaToken: this._captchaToken
            });

            this.paymentMethodId = response.paymentMethodId;

            this.updateUrl();

            this.processingComplete.emit(response.last4);
        } catch (e) {
            if (e.errorDef && e.errorDef == "CaptchaFailed") {
                throw e;
            } else {
                this.processingError.emit(e);
            }
        } finally {
            this.finishedDataEntry(true);
            this.appService.finishPageLoading();
            this._resetReCaptcha();
        }
    }

    // TODO: TOP-2624 remove after testing finished
    public override async payCart() {
        this.appService.triggerPageLoading("Processing payment, do not refresh your browser...");
        // Capture the payment method data before starting the process to ensure it isn't removed during processing
        const billingInfo = { ...this.paymentMethodData.billingInfo };
        const paymentMethodData = { ...this.paymentMethodData, billingInfo: billingInfo };

        // Wait for the response.
        await this.cartService.updateCart({
            guestEmailAddress: paymentMethodData.billingInfo.email,
            rememberPaymentMethod: paymentMethodData.rememberPaymentMethod,
            paymentMethodId: null //unset any previously saved paymentMethodId incase a previous attempt to use a saved method was made
        });

        let proceed = await this.cartService.refreshCart(this._cartGuid, paymentMethodData.type);
        if (proceed) {
            // we need to re-prepare the payment incase there was an error while trying to pay previously
            await this._preparePaymentAndAssessFees(false);

            if (!this._preparePaymentError) {
                try {
                    let postPaymentResponse = await this.fisService.payCart({
                        cartId: this._cartId,
                        paymentMethodData: paymentMethodData,
                        paymentToken: this._paymentToken,
                        inboundRedirectSourceId: this.inboundRedirectService.redirectSourceId,
                        validationToken: this._feeToken,
                        processorPaymentMethodType: paymentMethodData.processorPaymentMethodType,
                        captchaToken: this._captchaToken
                    });

                    if (postPaymentResponse.cartStatus) {
                        this.processingComplete.emit(postPaymentResponse);
                    } else {
                        // Display the appropriate message for the current payment method type
                        let notChargedMessage;
                        switch (paymentMethodData.type) {
                            case PaymentMethodTypeEnum.ECheck:
                                notChargedMessage = "Your account has not been charged.";
                                break;
                            case PaymentMethodTypeEnum.CreditCard:
                            default:
                                notChargedMessage = "Your card has not been charged.";
                                break;
                        }
                        this.messageService.notification("Unable to process payment. " + notChargedMessage + " Reason: " +
                            postPaymentResponse.errorMessage, "error", 5000);
                    }

                } catch (e) {
                    if (e.errorDef && e.errorDef == "CaptchaFailed") {
                        throw e;
                    } else {
                        this.processingError.emit(e);
                    }
                } finally {
                    this.finishedDataEntry(true);
                    this.appService.finishPageLoading();
                    this._resetReCaptcha();
                }
            } else {
                this.finishedDataEntry(true);
                this.appService.finishPageLoading();
            }
        } else {
            this.finishedDataEntry(true);
            this.appService.finishPageLoading();
        }
    }

    public override async handleCartChanges(cart: CartModel, cartItemCount: number) {
        if (cart.items.length > 0 && cart.items?.length != cartItemCount) {
            if (this.location.path() == "/checkout") {
                if (!this._paymentToken) {
                    return;
                }

                await this._preparePaymentAndAssessFees();
                this.appService.finishPageLoading();
            }
        }
    }

    //#endregion

    // TODO: TOP-2624
    //#region multipay methods
    private async _openMultiPayIFrame() {
        // These will be used for tracking whether or not a user's session has expired
        // because despite their documentation indicating they have a sessionExpired event
        // they don't actually have one
        let multiPaySessionDuration = 0;
        let multiPaySessionInterval = null;
        try {
            this._fisPopup = this.dialog.open(this.fisIframe, {
                // ensure users can't click outside of the window to close it
                disableClose: true,
                // Modals by default have max width 80vw so this is not an issue for mobile
                // 520px is the size FIS would render their dialog at so we will do the same size to look the same
                width: '520px',
                // Modals by default have a max height of 100%, so this is not an issue for shorter browsers,
                // 750px is the size FIS would render their dialog at so we will do the same size to look the same
                height: '750px',
            });
            this._fisPopup.afterOpened().subscribe(() => {
                // every _expiryTime (minutes) that the window is still open, log a cart event to keep the payment active
                this._modalExtensionInterval = setInterval(() => {
                    this.cartService.addEvent({message: "Extending the FIS session because the iframe is still open."}, this._paymentIdentifier);
                },  this._expiryTime * 60 * 1000);

                this.fisService.getCartHash(this.paymentMethodData.type, this._cartGuid, this._paymentIdentifier).then((cartHash) =>{
                    this._cartHash = cartHash;
                    // Every _cartHashRefreshTime (in seconds) check to see if the cart items have changed or not, and close the window if they have
                    this._cartChangeInterval = setInterval(async () => {
                        this.refreshCartHash();
                    }, this._cartHashRefreshTime * 1000);
                    // listen for changes to local storage so we can close the window if the cart has changed
                    this.windowService.addEventListener('storage', this.onWindow_storage);
                });
            });
            this._fisPopup.beforeClosed().subscribe(async () => {
                // clear the intervals because the dialog has been closed
                clearInterval(this._modalExtensionInterval);
                clearInterval(this._cartChangeInterval);
                // remove the listener because we don't care anymore
                this.windowService.removeEventListener('storage', this.onWindow_storage);
            });
            const multipay = this.document.querySelector('multipay-web') as MultiPay | null;

            if (!multipay) {
                throw "Fis multipay element has not loaded in time. This is likely temporary unless it's happening frequently.";
            }
            multipay.jwt = this._jwt;
            multipay.merchantAlias = this.fisService.multiPayMerchantAlias;
            multipay.merchantDisplayName = this.siteMetadataService.customization.title;
            multipay.showTerms = false;
            // This is the default value which will ensure we don't hit a 404 trying to load a file that doesn't exist
            // but we aren't using the terms at all so this is just the value we have to pass to ensure 
            // no error gets logged from their iframe
            multipay.termsUrl = "https://terms-and-conditions.url";
            multipay.enforceTermsLinkClick = false;
            multipay.showReceipt = false;
            multipay.requireEmail = true;
            multipay.sessionIdleTime = this._expiryTime;
            multipay.serviceFeeLabel = this.siteMetadataService.appConfiguration.convenienceFeeLabel ?? "Convenience Fee";
            multipay.allowedPaymentMethods = [this.paymentMethodData.type == PaymentMethodTypeEnum.ECheck ? 'bank' : 'card'];
            multipay.internationalAddress = false;
            multipay.militaryAddress = true;
            multipay.bankRoutingDoubleEntry = true;
            multipay.bankAccountDoubleEntry = true;
            multipay.defaultBillingAddress = {
                name: this.paymentMethodData.billingInfo.fullName,
                email: this.paymentMethodData.billingInfo.email,
            };

            const fisPaymentDetails = await this.fisService.getPaymentDetails(this._cartGuid, this._captchaToken);
            // reset the captcha after we use it because it will be invalid to reuse it again
            this._resetReCaptcha();
            multipay.userParts = fisPaymentDetails.userParts
                .map(part => {
                    return {
                        number: Number(part.number),
                        value: part.value
                    };
                });

            multipay.lineItems = fisPaymentDetails.lineItems.map(item => {
                return {
                    amount: item.amount,
                    quantity: item.quantity ?? 1,
                    name: item.name,
                    userParts: item.userParts.map(part => {
                        return {
                            number: Number(part.number),
                            value: part.value
                        };
                    })
                }
            });

            multipay.removeAllListeners();
            // handle events
            multipay.addEventListener('paymentSubmitted', (e: FisPaymentSubmittedResponse) => {
                // Add the only properties we got back that are useful to us
                this.paymentMethodData.billingInfo.email = e.detail.billingAddress.email;
                this.paymentMethodData.last4Digits = e.detail.paymentMethod.accountNumberLastFour;
                if (this.paymentMethodData.type == PaymentMethodTypeEnum.CreditCard) {
                    this.paymentMethodData.cardType = e.detail.paymentMethod.name;
                    this.paymentMethodData.processorPaymentMethodType = 
                        e.detail.paymentMethod.isDebit ? 'Debit' :
                            (e.detail.paymentMethod.name.replace(" ", ""))
                } else {
                    this.paymentMethodData.processorPaymentMethodType = "Bank";
                }

                // verify payment status
                this.fisService.verifyPayment(this._cartGuid, this.paymentMethodData, e.detail.paymentId, this._paymentIdentifier)
                    .then((response) => {
                        // process success/failure
                        this._processPaymentResponse(response);
                    }).catch((e) => {
                        this.processingError.emit(e);
                    }).finally(() => {
                        // wrap up
                        this.finishedDataEntry(true);
                        this.appService.finishPageLoading();
                        this._closeFisPopup();
                    });
            });

            multipay.addEventListener('cartCreated', (e) => {
                // This will be fired when a user selects their payment method in the multipay dialog
                // In a normal use case this happens very close to when the user starts a payment
                // however, this event will be fired additional times in the future if the session expires
                // as the user gets kicked back to this screen
                // Note: It is also possible for a user to manually navigate back to this screen at any point

                // If there is a multipaysessioninterval active and we've made it back into this event after
                // the session expiration time, it means we MOST LIKELY came back into this function after a session expiry
                // In order to ensure the user's cart is still valid, we will close the popup so we can refresh their cart
                // and they can try again with a fresh new payment
                // NOTE: I subtracted 30 seconds from the expiration time becuase their expiration timer
                // seems to start at a slightly different time than ours does so I gave us a little bit of buffer
                if (multiPaySessionInterval && multiPaySessionDuration > (this._expiryTime * 60) - 30) {
                    clearInterval(multiPaySessionInterval);
                    // refresh the cart incase they try to use a saved payment method right after this
                    this.cartService.refreshCart(this._cartGuid, this.paymentMethodData.type);
                    this.messageService.notification("Your session has expired. Please try again.", "error", 5000);
                    this._closeFisPopup();
                // If we don't have an interval active, it is most likely our first time in here, so we want to start an interval
                // (essentially a timer keeping track of elapsed seconds)
                // so we can manually keep track of whether the session has expired or not
                } else if(!multiPaySessionInterval){
                    multiPaySessionInterval = setInterval(() => {
                        multiPaySessionDuration++;
                    }, 1000);
                }
            });

            multipay.addEventListener('paymentFailed', (e) => {
                let error = `FIS Payment Failed: ${e.detail}`;
                if (e.errors) {
                    error += " "
                    for (const key in e.errors) {
                        error += `${e.errors[key]} `;
                    }
                }
                // Log the error in AWS
                this.errorHandlerService.handleError({ message: error });
                // Log the error on this payment in the database
                this.cartService.addEvent({message: error}, this._paymentIdentifier);

                // FIS has occasionally misreported successful payments as failed, so check if it really went through. 
                if (e.detail === "Unknown error occurred. Update Payment Information and retry.") {
                    this._closeFisPopup();
                    this.appService.triggerPageLoading("Processing payment, do not refresh your browser...");
                    setTimeout(() => {
                        // Note that since we do not have a successful response from FIS, we are passing in null for the paymentId and the paymentMethodData
                        this.fisService.verifyPayment(this._cartGuid, null, null, this._paymentIdentifier)
                            .then((response) => {
                                // process success/failure
                                this._processPaymentResponse(response);
                            }).catch((e) => {
                                this.processingError.emit(e);
                            }).finally(() => {
                                // wrap up
                                this.finishedDataEntry(true);
                                this.appService.finishPageLoading();
                            });
                    }, 5000); 
                }
            });

            multipay.addEventListener('jwtRequested', () => {
                this.ngZone.run(async () => {
                    multipay.jwt = await this._requestJwt(false, true);
                });
            });

            // Await this so that any errors that occur while trying to open the iframe happen within our context so our try catch will work properly
            await multipay.openIframe("#fis-iframe");
        } catch (e) {
            this._closeFisPopup();
            this.messageService.notification("Unable to initiate payment entry. Please refresh the page and try again.", "error", 5000);
            e.message = "FIS iframe failed to be created due to error: '" + e.message + "'";
            this.errorHandlerService.handleError(e);
        } finally {
            setTimeout(() => {
                this.appService.finishPageLoading();
            }, 1000);
        }
    }

    private _processPaymentResponse(postPaymentResponse: IFramePaymentResponse): void {
        if (postPaymentResponse.cartStatus) {
            this.processingComplete.emit(postPaymentResponse);
        } else {
            // Display the appropriate message for the current payment method type
            let notChargedMessage;
            switch (this.paymentMethodData.type) {
                case PaymentMethodTypeEnum.ECheck:
                    notChargedMessage = "Your account has not been charged.";
                    break;
                case PaymentMethodTypeEnum.CreditCard:
                default:
                    notChargedMessage = "Your card has not been charged.";
                    break;
            }
            this.messageService.notification("Unable to process payment. " + notChargedMessage + " Reason: " +
                postPaymentResponse.errorMessage, "error", 5000);
        }
    }
    //#endregion

    //#region helpers

    public async onSubmit_openIframe(e) {
        this.appService.triggerPageLoading();
        e.preventDefault();

        if (!this.validationService.runValidation(this.paymentDetailsForm, this._captchaToken !== null, false)) {
            this.appService.finishPageLoading();
            return;
        }

        this.cartService.cart$.pipe(first(c => !!c.cartGuid))
            .subscribe(async cart => {
                // Refresh the cart, if it succeeds it means we can still pay for this cart
                if (await this.cartService.refreshCart(cart.cartGuid, this.paymentMethodData.type)) {
                    // This may throw an exception, if it does, we will not continue with the rest of this function
                    await this.cartService.updateCart({
                        guestEmailAddress: this.paymentMethodData.billingInfo.email,
                        rememberPaymentMethod: false,
                        paymentProcessor: PaymentIntegrationEnumDto.Fis,
                        paymentMethodType: this.paymentMethodData.type,
                        paymentMethodId: null //unset any previously saved paymentMethodId incase a previous attempt to use a saved method was made
                    });
                    // request the start payment, but don't await it as we want this to happen asynchronously with other functionality
                    const startPaymentRequest = this.fisService.startPayment(cart.cartGuid);
                    // request jwt but do it asynchronously so we can continue with other functionality
                    const jwtRequest = this._requestJwt(true, true);
                    // run both calls at the same time and wait for both to come back before opening the iframe
                    Promise.all([startPaymentRequest, jwtRequest])
                        .then(([paymentIdentifier, jwtToken]) => {
                            this._paymentIdentifier = paymentIdentifier;
                            this._jwt = jwtToken;
                            this._openMultiPayIFrame();
                        });
                }
            });
    }

    private async refreshCartHash() {
        let newCartHash = await this.fisService.getCartHash(this.paymentMethodData.type, this._cartGuid, this._paymentIdentifier);
        if (this._cartHash != newCartHash) {
            this.messageService.notification("Your cart has changed. Please try again.", "error", 5000);
            this._closeFisPopup();
        }
    }

    private onWindow_storage = async (e: StorageEvent) => {
        if (e.key === 'cartLastModified') {
            this.refreshCartHash();
        }
    }

    public async onClick_openIFrame(event, action: TokenizerAction = null) {
        this.appService.triggerPageLoading();
        event.preventDefault = true;
        await this._openIFrame(action);
    }

    public onEnter_preventDefault(e) {
        // Just prevent form submission, the onClick event will trigger the IFrame
        e.preventDefault = true;
    }

    public tokenForEdit(action: TokenizerAction) {
        if (action === TokenizerAction.EditExisting) {
            return this.paymentMethodData.walletToken ?? this._paymentToken;
        }

        return '';
    }

    onCheck_agreeCreditCardDisclaimer(checked: boolean) {
        this._creditCardConfirmed = checked;
    }

    shouldDisableCreditCardSubmit() {
        return !this._creditCardConfirmed && this.paymentMethodData.type == PaymentMethodTypeEnum.CreditCard
    }

    private async _requestJwt(throwError:boolean = false, forMultiPay:boolean = false): Promise<string> {
        let response = await this.fisService.requestJwt(this.paymentMethodData.type, throwError, forMultiPay);

        return response?.token;
    }

    public onResolved_confirmCaptcha(e) {
        this._captchaToken = e;
    }

    private async _openIFrame(action: TokenizerAction) {
        this.fisIframeLoaded = false;
        try {
            this._fisPopup = this.dialog.open(this.fisIframe, {
                // ensure users can't click outside of the window to close it
                disableClose: true,
                // Modals by default have max width 80vw so this is not an issue for mobile
                width: '400px',
                // Modals by default have a max height of 10%, so this is not an issue for shorter browsers,
                // 515px is the min size before FIS starts scrolling
                height: '515px'
            });
            let token = await this._requestJwt(true);

            let tokenizer = this.document.querySelector('fis-tokenization') as Tokenizer | null;

            if (!tokenizer) {
                throw "Fis tokenizer element has not loaded in time. This is likely temporary unless it's happening frequently.";
            }

            tokenizer.token = this.tokenForEdit(action);
            tokenizer.jwt = token;
            tokenizer.canDelete = false;
            tokenizer.paymentType = this.paymentMethodData.type == PaymentMethodTypeEnum.ECheck ? 'bank' : 'card';
            tokenizer.styles = ``;

            tokenizer.removeAllListeners();
            // handle events
            tokenizer.addEventListener('rendered', (e) => {
                this.ngZone.run(async () => {
                    // when the iframe is rendered, set the flag to true so we can hide the loading icon
                    this.fisIframeLoaded = true;
                });
            });
            tokenizer.addEventListener('tokenAdded', (e) => {
                this.ngZone.run(async () => {
                    // Close the popup because we have a successful token and the iframe is unloaded
                    this._closeFisPopup();
                    await this._updateFisManagedFields(e.detail);
                });
            });

            tokenizer.addEventListener('tokenEdited', (e) => {
                this.ngZone.run(async () => {
                    // Close the popup because we have a successful token and the iframe is unloaded
                    this._closeFisPopup();
                    await this._updateFisManagedFields(e.detail);
                });
            });

            tokenizer.addEventListener('jwtRequested', () => {
                this.ngZone.run(async () => {
                    tokenizer.jwt = await this._requestJwt();
                });
            });

            tokenizer.addEventListener('errors', (e) => {
                this.ngZone.run(() => {
                    this._logError(e);
                });
            });

            // We need to await this so that if it throws an exception we can properly catch it
            // since this happens asynchronously
            await tokenizer.openIframe();
        } catch (e) {
            // Close the popup because there was an error and we couldn't load the iframe
            this._closeFisPopup();
            this.messageService.notification("Unable to initiate payment entry. Please refresh the page and try again.", "error", 5000);
            e.message = "FIS iframe failed to be created due to error: '" + e.message + "'";
            this.errorHandlerService.handleError(e);
        } finally {
            setTimeout(() => {
                this.appService.finishPageLoading();
            }, 1000);
        }
    }

    private _logError(error: any) {
        this.messageService.notification("Unable to complete payment entry. Please refresh the page and try again.", "error", 5000);
        this.errorHandlerService.handleError({ message: "FIS error encountered. Response from FIS was: " + (error?.detail ? error.detail.toString() : error.toString()) });

        this.appService.finishPageLoading();
    }

    private async _validateAndSubmit() {
        this.appService.triggerPageLoading('Validating information...');

        if (this._preparePaymentError) {
            this.fisFieldError = this._preparePaymentError;
        } else if (!this._paymentToken && this.paymentMethodData.type == this.PaymentMethodTypeEnum.CreditCard) {
            this.fisFieldError = "Payment details are required";
        } else {
            this.fisFieldError = null;
        }

        let additionalErrors: { [key: string]: string } = {};

        if (this.fisFieldError)
            additionalErrors.fisError = this.fisFieldError;

        // Only attempt to process the form if the rest of the form validation has passed
        if (this.validationService.runValidation(this.paymentDetailsForm, this._captchaToken !== null, false, additionalErrors)) {
            if (this.forEdit) {
                this.savePaymentMethod();

            // TODO (TOP-2624): remove after testing finished
            } else if(!this.useMultipay) {
                this.payCart();
            }
        } else {
            this.appService.finishPageLoading();
        }
    }

    private _initializeComponentJS() {
        // determine whether to use edit iframe or mulitpay iframe
        let scriptUrl: string;
        if ((this.forEdit || !this.useMultipay) && this.fisService.tokenizationUrl) {
            scriptUrl = this.fisService.tokenizationUrl;
        } else if (!this.forEdit && this.fisService.multiPayIFrameUrl) {
            scriptUrl = this.fisService.multiPayIFrameUrl;
        } else {
            // something is misconfigured, bail out
            this.messageService.notification("Unable to initialize payment entry form. Payment cannot be completed. Please refresh the page and try again.", "error", 5000);
            this.errorHandlerService.handleError({ message: `FIS iframe could not be initialized because ${(this.forEdit || !this.useMultipay) ? 'tokenizationUrl' : 'multiPayIFrameUrl'} was not provided.` });
            return;
        }

        // check if the script has already been created
        const existingScript = this.document.getElementById(this.COMPONENT_SCRIPT_ID) as HTMLScriptElement;
        if (existingScript) {
            if (existingScript.src !== scriptUrl) {
                existingScript.parentElement.removeChild(existingScript);
            } else {
                this.loading = false;
                this.fisFieldsAvailable = true;
                return;
            }
        }

        // create script element and set up constants
        const fisScript = this.document.createElement('script');
        fisScript.id = this.COMPONENT_SCRIPT_ID;
        fisScript.type = 'text/javascript';
        fisScript.async = false;
        fisScript.src = scriptUrl;

        // set component state once fis script has run
        fisScript.onload = () => {
            this.loading = false;
            this.fisFieldsAvailable = true;
        };

        // add fis script to document
        this.document.getElementsByTagName('head')[0].appendChild(fisScript);
    }

    private async _preparePaymentAndAssessFees(displayCalculating: boolean = true) {
        if (displayCalculating) this.appService.triggerPageLoading("Calculating Convenience Fee...");
        this._preparePaymentError = null;

        try {
            this.fisFieldsAvailable = false;

            // If a payment is being made, assess the fees now so they can be displayed to the user before the payment is finalized.
            let response = await this.fisService.preparePaymentAndAssessFees({
                cartId: this._cartId,
                paymentMethodData: this.paymentMethodData,
                paymentToken: this._paymentToken,
                inboundRedirectSourceId: this.inboundRedirectService.redirectSourceId
            });
            if (response.paymentToken) {
                this.paymentMethodData.processorPaymentMethodType = response.processorPaymentMethodType;

                // Save the fee token so it can be used to submit the payment later.
                this._feeToken = response.paymentToken;
                this.paymentProvider.defaultConfig.overrideConvenienceFee(response.convenienceFee);
                if (displayCalculating) {
                    this.fisService.showConfirmationDialog(response.convenienceFee);
                }
            }
        } catch (e) {
            this._preparePaymentError = e?.errorMessage && e.errorMessage != "An internal error occurred."
                ? e.errorMessage
                : "An error occurred while calculating convenience fees. Please re-enter your payment information and try again.";

            // if the cart is undefined then it has timed out do not throw this error
            // the user is going to be redirected out of this flow and a message has already been show
            if (this.cartService.cartGuid) {
                throw e;
            }
        } finally {
            this.fisFieldsAvailable = true;
        }
    }

    private async _updateFisManagedFields(response: FisTokenResponse) {
        this._paymentToken = response.token;
        this.fisFieldError = null;

        if (this.cartService.cartExists()) {
            await this.cartService.updateCart({
                paymentMethodId: null
            }).catch(e => {
                this.cartService.refreshCart(this.cartService.cartGuid);
                throw e;
            });
        }

        if (this.paymentMethodData.type == this.PaymentMethodTypeEnum.CreditCard) {
            this.paymentMethodData.cardNumber = response.accountSuffix;
            this.paymentMethodData.cardExpiry = response.expirationDateMonth.toString().padStart(2, '0') + '/' + response.expirationDateYear.toString().substring(2);
            // Sometimes they don't give us a brand back but cardType is required, to get around that issue we can specify "NA" and set it back to null later
            this.paymentMethodData.cardType = response.brand ?? "NA";
        } else if (this.paymentMethodData.type == this.PaymentMethodTypeEnum.ECheck) {
            this.paymentMethodData.echeckRoutingNumber = response.routingSuffix;
            this.paymentMethodData.echeckAccountNumber = response.accountSuffix;

            this.paymentMethodData.echeckAccountType = ECheckAccountTypeEnum[response.bankAccountType.charAt(0) + response.bankAccountType.slice(1).toLowerCase()];
            this.paymentDetailsForm.get(this.ECHECK_FIELDS.checktype).setValue(this.paymentMethodData.echeckAccountType);

            this.paymentMethodData.echeckAccountOwnerType = ECheckAccountOwnerTypeEnum[response.accountHolderType.charAt(0) + response.accountHolderType.slice(1).toLowerCase()];
            this.paymentDetailsForm.get(this.ECHECK_FIELDS.checkowner).setValue(this.paymentMethodData.echeckAccountOwnerType);
        }

        this.errorHandlerService.logErrorToServer("FIS Token Response: " + JSON.stringify(response));
        if (!response.billingAddress.trim() || !response.billingCity.trim() || !response.billingPostalCode.trim() || !response.billingState.trim() || !response.phoneNumber.trim()) {
            this.errorHandlerService.logErrorToServer("FIS response did not contain all billing information.");
        }

        this.paymentDetailsForm.get(this.billingInfoComponent.BILLING_INFO_FIELDS.addressLine1).setValue(response.billingAddress);
        this.paymentDetailsForm.get(this.billingInfoComponent.BILLING_INFO_FIELDS.addressCity).setValue(response.billingCity);
        this.paymentDetailsForm.get(this.billingInfoComponent.BILLING_INFO_FIELDS.addressState).setValue(StateOrProvince.GetByCode(response.billingState));
        this.paymentDetailsForm.get(this.billingInfoComponent.BILLING_INFO_FIELDS.addressZip).setValue(response.billingPostalCode);
        this.paymentDetailsForm.get(this.billingInfoComponent.BILLING_INFO_FIELDS.phone).setValue(response.phoneNumber);

        if (!this.forEdit) {
            await this._preparePaymentAndAssessFees();
        }

        this.appService.finishPageLoading();
    }

    private _closeFisPopup() {
        if (this._fisPopup) {
            this._fisPopup.close();
            this._fisPopup = null;
        }
    }

    public onClick_refreshCart() {
        this.cartService.refreshCart(this._cartGuid, this.paymentMethodData.type);
    }

    private _resetReCaptcha() {
        this.reCaptcha.reset();
        this._captchaToken = null;
    }

    //#endregion
}

class FisTokenResponse {
    token?: any;
    accountSuffix?: any;
    expirationDateMonth?: any;
    expirationDateYear?: any;
    routingSuffix?: any;
    brand?: any;
    bankAccountType?: any;
    accountHolderType?: any;
    billingAddress?: string;
    billingCity?: string;
    billingPostalCode?: string;
    billingState?: string;
    phoneNumber?: any;
}

/** Mapping of what's returned from the "paymentSubmitted" event from the MultiPayiFrame */
class FisPaymentSubmittedResponse {
    detail: {
        /** The total to pay exclusive of fees */
        amount: number,
        billingAddress: {
            city: string,
            /** e.g. "US" */
            countryCode: string,
            /** e.g. "United States" */
            countryName: string, 
            email: string,
            isValid: Function,
            name: string,
            /** e.g. "+12312312314" */
            phoneNumber: string, 
            postalCode: string,
            /** e.g. "AL" */
            state: string, 
            /** e.g. "Alabama" */
            stateName: string, 
            street: string
        },
        /** A confirmation number for this payment, e.g. "4006832232" */
        confirmationNumber: string,
        /** Date in the following format: "2025-01-27T14:24:35" */
        date: string,
        /** The total of all FEES paid (e.g. convenience fee, etc) */
        feeTotal: number,
        /** List of all fees paid */
        fees: any[],
        /** unique guid representing this payment. To be used by our API for referencing the finalized payment */
        paymentId: string,
        /** These are the only properties available to us that describe the payment method (regardless of how they paid) */
        paymentMethod: {
            /** last 4 digits of the card # or account number */
            accountNumberLastFour: string,
            /** indicates if this was a debit card (rather than a credit card) */
            isDebit: boolean,
            /** will be "Visa"/etc if card, or the name of the bank if echeck */
            name: string,
            /** will be "Visa" or the like, or "bank" if echeck */
            paymentIconName: string,
            /** can be 'bank' or 'card */
            type: string
        },
        /** total amount paid, including convenience fees */
        totalAmount: number 
    }
}

/** Represents how the iframe is being opened.
 * If a token for the payment method already exists, EditExisting can be passed to edit that method.
 * AddNew must be used when adding a payment initially, or to change the card number.
 */
enum TokenizerAction {
    AddNew,
    EditExisting
}
